<template>
    <div class="number_input">
        <button class="minus_button" @click.prevent="minus">-</button>
        <input type="text" class="input" v-model="input_value" @keypress="filter_numbers">
        <button class="plus_button" @click.prevent="plus">+</button>
    </div>
</template>
<script>
export default {
    name: 'number-input',
    props: {
        value: [Number, String],
        min: [Number, String],
        max: [Number, String]
    },
    data(){
        return {
            input_value: 0
        }
    },
    mounted(){
        if(this.value=='' || this.value==0){
            this.input_value = parseInt(this.min)
        }else{
            this.input_value = parseInt(this.value)
        }
        
    },
    watch: {
        input_value(){
            if(parseInt(this.input_value)>parseInt(this.max))
                this.input_value = parseInt(this.max)
            if(isNaN(this.input_value))
                this.input_value = 0
            this.$emit('input', this.input_value)
            this.$emit('calculate', this.input_value)
        },
        value(){
            this.input_value = this.value
        }
    },
    methods: {
        filter_numbers(evt){
            evt = (evt) ? evt : window.event;
            var charCode = (evt.which) ? evt.which : evt.keyCode;
            if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
                evt.preventDefault();
            } else {
                return true;
            }
        },
        plus(){
            if(parseInt(this.input_value)<parseInt(this.max))
                this.input_value = parseInt(this.input_value)+1
        },
        minus(){
            if(parseInt(this.input_value)>parseInt(this.min))
                this.input_value = parseInt(this.input_value)-1
        }
    }
}
</script>
<style scoped>
    .number_input{
        display: flex;
        margin-left: 20px;
    }
    .input{
        box-sizing: border-box;
        background: #FFFFFF 0% 0% no-repeat padding-box;
        box-shadow: inset 0px 3px 6px #00000029;
        border: 1px solid #20409780;
        border-radius: 4px;
        opacity: 1;
        height: 39px;
        width: 60px;
        text-align: center;
        font: normal normal bold 20px/25px Helvetica Neue;
        letter-spacing: 0px;
        color: #204097;
        opacity: 1;
        position: relative;
        z-index: 2;
        width: 28px;
        font-size: 14px;
        font-weight: normal;
        height: 28px;
        border-radius: 0;
        box-shadow: none;
    }
    .minus_button, .plus_button{
        height: 28px;
        border: 0;
        background: #94A3C9 0% 0% no-repeat padding-box;
        box-shadow: inset 0px 3px 6px #00000029;
        box-shadow: 0;
        background: #214393;
        border-radius: 4px;
        border-radius: 0;
        opacity: 1;
        width: 32px;
        width: 28px;
        font: normal normal bold 20px/25px Helvetica Neue;
        letter-spacing: 0px;
        color: #FFFFFF;
        opacity: 1;
        text-align: center;
        line-height: 3px;
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        z-index: 1;
        cursor: pointer;
        transition: background .5s;
        padding: 0;
    }
    .minus_button:hover, .plus_button:hover{
        background: #828fb3 0% 0% no-repeat padding-box;
    }
    .minus_button{
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
        /* padding-right: 10px; */
        /* margin-right: -5px; */
    }
    .plus_button{
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
        /* padding-left: 10px; */
        /* margin-left: -5px; */
    }
</style>