<template>
    <div class="tab-input">
        <div v-for="(option, i) in options" :key="'t-r-'+i">
            <input type="radio" :name="'tab-radio-'+_uid" :id="'tab-radio-'+_uid+'-'+option.value" v-model="val" :value="option.value">
            <label :for="'tab-radio-'+_uid+'-'+option.value" >
                <span>{{option.label}}</span>
            </label>
        </div>
    </div>
</template>
<script>
export default {
    name: 'tab-input',
    props: ['value', 'options'],
    data(){
        return {
            val: ''
        }
    },
    mounted(){
        this.val = this.options[0].value
    },
    watch: {
        val(){
            this.$emit('input', this.val)
            this.$emit('calculate', this.val)
        },
        value(){
            this.val = this.value
        }
    },
    computed: {
        uid_(){
            return this._uid
        }
    }
}
</script>
<style scoped>
    input{
        opacity: 0;
        position: absolute;
        /* left: -10000px; */
        visibility: hidden;
        width: 0;
        height: 0;
    }
    .tab-input{
        height: 38px;
        background: #8E9ECA 0% 0% no-repeat padding-box;
        box-shadow: inset 0px 3px 6px #00000029;
        border-radius: 19px;
        opacity: 1;
        display: flex;
        overflow: hidden;
        flex-grow: 1;
    }
    .tab-input div{
        flex-grow: 1;
    }
    .tab-input label{
        height: 38px;
        background: transparent;
        box-shadow: none;
        border-radius: 19px;
        opacity: 1;
        display: flex;
        align-items: center;
        padding: 0 20px;
        justify-content: center;
        font: normal normal normal 16px/24px Helvetica Neue;
        letter-spacing: 0px;
        color: #FFFFFF;
        opacity: 1; 
        cursor: pointer;
    }
    .tab-input input:checked + label{
        height: 38px;
        background: #204097 0% 0% no-repeat padding-box;
        box-shadow: inset 0px 3px 6px #00000029;
        border-radius: 19px;
        opacity: 1;
        cursor: default;
    }
    @media screen and (max-width: 768px){
        .tab-input label{
            line-height: 18px !important;
            font: normal normal normal 16px/18px Helvetica Neue !important;
            }
    }
</style>