<template>
    <div class="ddlc-switch">
        <label>
            <div class="no-text" v-if="no">{{no}}</div>
            <input type="checkbox" v-model="val" />
            <div class="checkbox-emulation"></div>
            <div class="yes-text" v-if="yes">{{yes}}</div>
        </label>
    </div>
</template>
<script>
export default {
    name: 'switch-input',
    props: ['value', 'yes', 'no'],
    data(){
        return {
            val: false
        }
    },
    mounted(){
        this.val = this.value
    },
    watch: {
        val(){
            this.$emit('input', this.val)
            this.$emit('calculate', this.val)
        },
        // value(){
        //     this.val = this.value
        // }
    },
    computed: {
        uid_(){
            return this._uid
        }
    }
}
</script>
<style scoped>
    input{
        opacity: 0;
        /* position: absolute; */
        visibility: hidden;
        width: 0;
        height: 0;
    }
    label{
        cursor: pointer;
        display: flex;
        align-items: center;
    }
    .checkbox-emulation{
       width: 71px;
       margin-left: 10px;
       margin-right: 10px;
        height: 39px;
        background: #FFFFFF 0% 0% no-repeat padding-box;
        box-shadow: inset 0px 3px 6px #00000029;
        border: 1px solid #20409780;
        border-radius: 20px;
        opacity: 1;
        position: relative;
    }
    .checkbox-emulation:before{
        content: "";
        display: block;
        width: 59px;
        height: 29px;
        background: #20409733 0% 0% no-repeat padding-box;
        box-shadow: inset 0px 3px 6px #00000029;
        border-radius: 15px;
        opacity: 1;
        position: absolute;
        left: 5px;
        top: 4px;
        transition: all .5s;
    }
    .checkbox-emulation:after{
        content: "";
        display: block;
        width: 29px;
        height: 29px;
        background: #2040974D 0% 0% no-repeat padding-box;
        box-shadow: 0px 3px 6px #00000029;
        opacity: 1;border-radius: 50%;
        position: absolute;
        left: 5px;
        top: 4px;
        transition: all .5s;
    }
    input:checked + .checkbox-emulation:before{
        background: #20409780 0% 0% no-repeat padding-box;
    }
    input:checked + .checkbox-emulation:after{
        background: #204097 0% 0% no-repeat padding-box;
        left: 35px;
    }
    .label-text{
        text-align: left;
        font: normal normal normal 20px/24px Helvetica Neue;
        letter-spacing: 0px;
        color: #000000;
        opacity: 1;
    }
</style>