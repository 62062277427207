<template>
    <div class="ddlc-checkbox">
        <label :for="'checkbox'+uid_">
            <input :id="'checkbox'+uid_" type="checkbox" v-model="val" :value="itemvalue" />
            <div class="checkbox-emulation"></div>
            <div class="label-text" v-if="url"><a :href="url" target="_blank">{{label}}</a></div>
            <div class="label-text" v-else>{{label}}</div>
        </label>
    </div>
</template>
<script>
export default {
    name: 'checkbox-input',
    props: ['value', 'label', 'itemvalue', 'url'],
    data(){
        return {
            val: ''
        }
    },
    mounted(){
        this.val = this.value
    },
    watch: {
        val(){
            this.$emit('input', this.val)
            this.$emit('calculate', this.val)
        },
        value(){
            this.val = this.value
        }
    },
    computed: {
        uid_(){
            return this._uid
        }
    }
}
</script>
<style scoped>
    input{
        opacity: 0;
        /* position: absolute; */
        visibility: hidden;
        width: 0;
        height: 0;
    }
    .ddlc-checkbox{
        margin-right: 15px;
    }
    label{
        cursor: pointer;
        display: flex;
        align-items: center;
    }
    .checkbox-emulation{
        width: 21px;
        height: 21px;
        min-width: 21px;
        min-height: 21px;
        max-width: 21px;
        max-height: 21px;
        margin-right: 10px;
        background: #FFFFFF 0% 0% no-repeat padding-box;
        box-shadow: inset 0px 3px 6px #00000029;
        border: 1px solid #20409780;
        border-radius: 4px;
        opacity: 1;
    }
    input:checked + .checkbox-emulation:after{
        content: "";
        width: 13px;
        height: 13px;
        margin-top: 3px;
        margin-left: 3px;
        background: #204097 0% 0% no-repeat padding-box;
        box-shadow: 0px 3px 6px #00000029;
        border-radius: 2px;
        opacity: 1;
        display: block;
    }
    .label-text{
        text-align: left;
        font: normal normal normal 20px/24px Helvetica Neue;
        letter-spacing: 0px;
        color: #000000;
        opacity: 1;
    }
</style>