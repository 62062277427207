<template>
    <div>
        <div class="repeatable_title">{{field.label}} {{iteration+1}}</div>

        <div v-for="(field_, f_index) in inner_field.fields" :key="'field-'+f_index">
              <div v-if="is_field_visible(field_, f_index)">
                <div>
                  <div v-if="field_.type=='tabs' || field_.type=='textarea'  || field_.type=='divider'">
                    <tab-input v-if="field_.type=='tabs'" v-model="field_.value" :options="field_.options" @calculate="calculate($event, field_)" />
                    <div v-if="field_.type=='textarea'">
                      <label>{{field_.label}}</label>
                      <textarea-input v-model="field_.value" />
                    </div>
                    <hr v-if="field_.type=='divider'" />
                  </div>
                  <div v-else class="field-block-field" :style="{gridTemplateColumns: (field_.result?'2fr 3fr 1fr':'2fr 4fr'), display: field_.label.trim()==''?'none':'grid'}">
                    <div class="label" :style="{whiteSpace: field_.type=='text'?'nowrap':'wrap'}">
                      {{field_.label}}
                    </div>
                    <div class="field">
                      <div class="prefix">
                        {{field_.prefix}}
                      </div>
                      <div class="input">
                        <number-input v-model="field_.value" v-if="field_.type=='number'" :min="field_.min_max.min" :max="field_.min_max.max" @calculate="calculate($event, field_)" />
                        <switch-input v-model="field_.value" v-if="field_.type=='switch'" :yes="field_.yes_no.yes" :no="field_.yes_no.no" @calculate="calculate($event, field_)" />
                        <select-input v-model="field_.value" v-if="field_.type=='select'" :options="field_.options" @calculate="calculate($event, field_)" />
                        <slider-input v-model="field_.value" v-if="field_.type=='slider'" :min="field_.min_max.min" :max="field_.min_max.max" @calculate="calculate($event, field_)" />
                        <radio-group-input v-model="field_.value" v-if="field_.type=='radio'" :options="field_.options" @calculate="calculate($event, field_)" />
                        <checkbox-group-input v-model="field_.value" v-if="field_.type=='checkbox'" :options="field_.options" @calculate="calculate($event, field_)" />
                      </div>
                      <!-- <div class="suffix">
                        {{field_.suffix}}
                      </div> -->
                      <!-- / {{field_.result}} / {{field_.value}} / -->

                    </div>
                    <div class="calculation" v-if="field_.result">
                      = {{(field_.result)}} €
                    </div>
                  </div>
                </div>
              </div>
            </div>
    </div>
</template>
<script>
import NumberInput from './number-input.vue'
// import TextInput from './text-input.vue'
import TextareaInput from './textarea-input.vue'
import CheckboxGroupInput from './checkboxgroup-input.vue'
import RadioGroupInput from './radiogroup-input.vue'
import SliderInput from './slider-input.vue'
import SwitchInput from './switch-input.vue'
import SelectInput from './select-input.vue'
import TabInput from './tab-input.vue'
export default {
    props: {
        field: Object,
        iteration: Number,
        parent: [Array, Object],
    },
    data(){
        return {
            inner_field: {}
        }
    },
    mounted(){
        this.inner_field  = this.field
        this.calculate()
    },
    watch: {
        inner_field: {
            deep: true,
            handler(){
                this.$emit('input', this.inner_field)
            }
        },
        field: {
            deep: true,
            handler(){
                this.inner_field = this.field
            }
        }
    },
    components: {
        NumberInput,
        // TextInput, 
        SliderInput,
        TextareaInput,
        SwitchInput,
        SelectInput,
        TabInput,
        RadioGroupInput,
        CheckboxGroupInput,
    },
    methods: {
        get_valid_calculation(field){
            console.log(field)
            let default_calculation = false
            field.calculations.forEach(calculation=>{
                if(calculation.condition.type=='default')
                  default_calculation = calculation
            })

            return default_calculation
        },
        get_field_value(search_id){
            let value = 0
            this.field.fields.forEach(field => {
              // console.log(field.id, search_id, field.value, parseFloat(field.value))
                if(field.id==search_id)
                  value = field.value
            })
            this.parent.forEach(field => {
                if(field.id==search_id)
                  value = field.value
            })
            return value
        },
        generate_equation(calculation, data){
            let string = ''
            if(calculation!==false)
                calculation.items.forEach(item=>{
                if(item.input=='this')
                    string+=data+item.operator
                else if(item.input=='number')
                    string+=item.number+item.operator
                else
                    string+=this.get_field_value(item.input)+item.operator
                })
            return string.replace('=', '')
        },
        calculate(){
            this.field.fields.forEach((field, index)=>{
                if(field.type=='text')
                  field.value = 1

                // field.is_visible = this.is_field_visible(field)
                // if(this.is_field_visible(field)){
                  let equation = this.generate_equation(this.get_valid_calculation(field), field.value)
                  let result = false
                  // console.log(equation, field)
                  if(equation!=='')
                  result = eval(equation)
                  if(typeof result ==='number')
                    field.result = result.toFixed(2)
                  else
                    field.result = 0
                  this.$set(this.inner_field.fields, index, field)  
                  this.$emit('calculate')
                // }else{
                //   field.result = 0
                //   this.$set(this.inner_field.fields, index, field)  
                // }
            })
        },
        is_field_visible(field, index){
            let result = true
            console.log(field.conditions.length, this.iteration, index)
            if(field.conditions.length>1 && this.iteration>0){
                if(index==0)
                  result = true
                else
                  result = false
            }else{
              if(field.conditions.length>0){
                  let conclusions_string = '';
                  field.conditions.forEach((condition, index)=>{
                    // console.log('condition', condition)
                    let conclusion = ''
                    let operator = ''
                    // console.log(parseFloat(this.get_field_value(condition.field)))
                    if((this.get_field_value(condition.field))==(condition.value))
                        conclusion='true';
                    else
                        conclusion='false';
                    
                    if(index!==field.conditions.length-1)
                        if(condition.operand=='and')
                        operator=' && '
                        else
                        operator=' || '
                    
                    conclusions_string+=conclusion+operator
                  })
                  // console.log(conclusions_string)
                  result = eval(conclusions_string);
              }
            }
            // field.is_visible = result
            this.$set(this.inner_field.fields[index], 'is_visible', result)  
            return result
        }
    }
}
</script>
<style scoped>
  .field-block-field{
    display: grid;
    grid-template-columns: 2fr 3fr 1fr;
    padding: 10px 0;
    font: normal normal normal 20px/24px Helvetica Neue;
    letter-spacing: 0px;
    color: #000000;
  }
  .field-block-field .label{
    flex-basis: 30%;
  }
  .field-block-field .field{
    text-align: left;
    /* background: red; */
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-items: center;
  }
  .field-block-field .calculation{
    font: normal normal bold 20px/25px Helvetica Neue;
    text-align: right;
  }

  .field-block-field{
    display: grid;
    grid-template-columns: 2fr 3fr 1fr;
    grid-column-gap: 10px;
    padding: 10px 0;
    padding: 0;
    margin-bottom: 10px;
    font: normal normal normal 20px/24px Helvetica Neue;
    letter-spacing: 0px;
    color: #000000;
  }
  .field-block-field .label{
    flex-basis: 30%;
    padding: 10px;
    background-color: #F7F9FF;
    color: #818292;
    font-size: 14px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .field-block-field .field{
    text-align: left;
    /* background: red; */
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-items: center;
    padding: 10px;
    background-color: #F7F9FF;
    color: #818292;
    font-size: 14px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .field-block-field .calculation{
    font: normal normal bold 20px/25px Helvetica Neue;
    text-align: right;
    padding: 10px;
    background-color: #F7F9FF;
    color: #818292;
    font-size: 14px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .repeatable_title{
      font-weight: bold;
      font-size: 22px;
      display: flex;
      align-items: center;
      white-space: nowrap;
      color: rgba(0,0,0,.3);
  }
  .repeatable_title:after{
      content: '';
      height:1px;
      margin-left: 10px;
      background: rgba(0,0,0,.3);
      display: block;
      flex-basis: 100%;
  }
  @media screen and (max-width: 768px){
    .sections-block .section{
      width: 20vw;
      height: 25vw;
    }
    .sections-block .section .image{
      width: 20vw;
      height: 20vw;
    }
    .sections-block .section .image img{
      width: 20vw;
      height: 20vw;
    }
  }
  @media screen and (max-width: 768px){
    .section-content .fields-block{
      padding: 15px;
    }
    .field-block-field{
      display: flex;
      flex-wrap: wrap;
      grid-template-columns: none !important;
      grid-gap: 0 !important;
    }
    .field-block-field .label, .field-block-field .field{
      flex-basis: 50%;
      box-sizing: border-box;
      display: block; 
      clear: both;
      float: none;
    }
    .field-block-field .field div{
      float: right;
    }
    .field-block-field .label{
      white-space: pre-line;
      word-break: break-all;
    }
    .section-content .fields-block .fields-block-header{
      margin: -15px;
      padding: 15px 15px;
      margin-bottom: 15px;
    }
    .section-content h1{
      font: normal normal bold 26px/39px Helvetica Neue;
    }
    .field-block-field .calculation{
      flex-basis: 100%;
      margin-top: 10px;
    }
    .sections-block .section{
      width: 23vw;
      height: 26vw;
    }
    .sections-block .section .image{
      width: 23vw;
      height: 23vw;
    }
    .sections-block .section .image img{
      width: 23vw;
      height: 23vw;
    }
    .sections-block .section .title{
      font-size: 2vw;
      /* word-break: break-all; */
      text-overflow: ellipsis;
      overflow: hidden;
      line-height: 2vw;
    }
    .fields-block-totals .logo, .field-block-field .prefix, .field-block-field .suffix{
      display: none !important;
    }
    .fields-group{
      display: block !important;
      margin-bottom: 0px !important;
    }
    .fields-group div{
      flex-basis: 100% !important;
      margin-bottom: 20px !important;
    }
    .contacts_footer_block{
      display: block !important;
    }
    .contacts_footer_block .ddlc-checkbox{
      margin-bottom: 20px !important;
    }
    .tab-input div{
      white-space: nowrap;
    }
    .field-block-field{
      grid-template-columns: 1fr 1fr 1fr !important;
      white-space: nowrap;
      align-items: center;
      grid-gap: 10px;
    }
    .field-block-field .field{
      display: block !important;
    }
    .buttons-block{
      display: block ;
      text-align: center;
    }
    .buttons-block button{
      margin-bottom: 10px;
    }
  }
</style>