<template>
    <input type="text" v-model="val">
</template>
<script>
export default {
    name: 'text-input',
    props: ['value'],
    data(){
        return {
            val: ''
        }
    },
    mounted(){
        this.val = this.value
    },
    watch: {
        val(){
            this.$emit('input', this.val)
            this.$emit('calculate', this.val)
        },
        value(){
            this.val = this.value
        }
    }
}
</script>
<style scoped>
    input{
        height: 39px;
        background: #FFFFFF 0% 0% no-repeat padding-box;
        box-shadow: inset 0px 3px 6px #00000029;
        border: 1px solid #20409780;
        border-radius: 4px;
        opacity: 1;
        margin-right: 10px;
    }
</style>