<template>
    <div class="checkbox-group">
        <checkbox-input v-for="(option, i) in options" :key="'o'+i" v-model="val" :label="option.label" :group="_uid" :itemvalue="option.value"/>
    </div>
</template>
<script>
import CheckboxInput from './checkbox-input.vue'
export default {
    name: 'checkbox-group',
    components: {
        CheckboxInput
    },
    props: ['value', 'options'],
    data(){
        return {
            val: [],
        }
    },
    watch:{
        val(){
            this.$emit('input', this.val)
            this.$emit('calculate', this.val)
        }
    }

}
</script>
<style scoped>
    div.checkbox-group{
        display: flex;
    }
</style>