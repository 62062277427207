<template>
    <div class="radio-group">
        <radio-input v-for="(option, i) in options" :key="'o'+i" v-model="val" :label="option.label" :group="_uid" :itemvalue="option.value"/>
    </div>
</template>
<script>
import RadioInput from './radio-input.vue'
export default {
    name: 'radio-group',
    components: {
        RadioInput
    },
    props: ['value', 'options'],
    data(){
        return {
            val: '',
        }
    },
    watch:{
        val(){
            this.$emit('input', this.val)
            this.$emit('calculate', this.val)
        }
    }

}
</script>
<style scoped>
    div.radio-group{
        display: flex;
    }
    @media screen and (max-width: 768px){
        div.radio-group{
            flex-wrap: wrap;
        }
        div.radio-group div{
            margin-bottom: 10px;
        }
    }
</style>