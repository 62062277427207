<template>
    <select v-model="val">
        <option v-for="(option, i) in options" :key="'opt'+i" :value="option.value">{{option.label}}</option>
    </select>
</template>
<script>
export default {
    name: 'select-input',
    props: ['value', 'options'],
    data(){
        return {
            val: ''
        }
    },
    mounted(){
        this.val = this.value
    },
    watch: {
        val(){
            this.$emit('input', this.val)
            this.$emit('calculate', this.val)
        },
        value(){
            this.val = this.value
        }
    }
}
</script>
<style scoped>
    select{
        height: 39px;
        background: #FFFFFF 0% 0% no-repeat padding-box;
        box-shadow: inset 0px 3px 6px #00000029;
        border: 1px solid #20409780;
        border-radius: 4px;
        opacity: 1;
        text-align: left;
        font: normal normal bold 16px/25px Helvetica Neue;
        letter-spacing: 0px;
        color: #204097;
        opacity: 1;
        -moz-appearance:revert; /* Firefox */
        -webkit-appearance:revert; /* Safari and Chrome */
        appearance:revert;
        width: 100%;
    }
</style>