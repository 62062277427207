<template>
    <input type="range" :min=min :max=max v-model="val">
</template>
<script>
export default {
    name: 'slider-input',
    props: ['value', 'min', 'max'],
    data(){
        return {
            val: ''
        }
    },
    mounted(){
        this.val = this.value
    },
    watch: {
        val(){
            this.$emit('input', this.val)
            this.$emit('calculate', this.val)
        },
        value(){
            this.val = this.value
        }
    },
    computed: {
        uid_(){
            return this._uid
        }
    }
}
</script>
<style scoped>
    input[type=range] {
        -webkit-appearance: none;
        margin: 10px 0;
        width: 100%;
    }
    input[type=range]:focus {
        outline: none;
    }
    input[type=range]::-webkit-slider-runnable-track {
        width: 100%;
        height: 3px;
        cursor: pointer;
        box-shadow: 0px 0px 0px #000000, 0px 0px 0px #0d0d0d;
        background: #707070;
        border-radius: 25px;
        border: 0px solid #000101;
    }
    input[type=range]::-webkit-slider-thumb {
        box-shadow: 0px 0px 0px #000000, 0px 0px 0px #0d0d0d;
        border: 0px solid #000000;
        width: 29px;
        height: 29px;
        border-radius: 29px;
        background: #204097 0% 0% no-repeat padding-box;
        box-shadow: 0px 3px 6px #00000029;
        cursor: pointer;
        -webkit-appearance: none;
        margin-top: -13px;
    }
    input[type=range]:focus::-webkit-slider-runnable-track {
        background: #707070;
    }
    input[type=range]::-moz-range-track {
        width: 100%;
        height: 3px;
        cursor: pointer;
        animate: 0.2s;
        box-shadow: 0px 0px 0px #000000, 0px 0px 0px #0d0d0d;
        background: #707070;
        border-radius: 25px;
        border: 0px solid #000101;
    }
    input[type=range]::-moz-range-thumb {
        box-shadow: 0px 0px 0px #000000, 0px 0px 0px #0d0d0d;
        border: 0px solid #000000;
        width: 29px;
        height: 29px;
        border-radius: 29px;
        background: #204097 0% 0% no-repeat padding-box;
        box-shadow: 0px 3px 6px #00000029;
        cursor: pointer;
        margin-top: -13px;
    }
    input[type=range]::-ms-track {
        width: 100%;
        height: 3px;
        cursor: pointer;
        animate: 0.2s;
        background: transparent;
        border-color: transparent;
        border-width: 39px 0;
        color: transparent;
    }
    input[type=range]::-ms-fill-lower {
        background: #707070;
        border: 0px solid #000101;
        border-radius: 50px;
        box-shadow: 0px 0px 0px #000000, 0px 0px 0px #0d0d0d;
    }
    input[type=range]::-ms-fill-upper {
        background: #707070;
        border: 0px solid #000101;
        border-radius: 50px;
        box-shadow: 0px 0px 0px #000000, 0px 0px 0px #0d0d0d;
    }
    input[type=range]::-ms-thumb {
        box-shadow: 0px 0px 0px #000000, 0px 0px 0px #0d0d0d;
        border: 0px solid #000000;
        width: 29px;
        height: 29px;
        border-radius: 29px;
        background: #204097 0% 0% no-repeat padding-box;
        box-shadow: 0px 3px 6px #00000029;
        opacity: 1;
        cursor: pointer;
        margin-top: -13px;
    }
    input[type=range]:focus::-ms-fill-lower {
        background: #707070;
    }
    input[type=range]:focus::-ms-fill-upper {
        background: #707070;
    }
</style>